export const FirebaseConfig = {
	"projectId": "oportador-7aba2",
	"appId": "1:808751182390:web:3640d6129c4b2115205e97",
	"databaseURL": "https://oportador-7aba2-default-rtdb.firebaseio.com",
	"storageBucket": "oportador-7aba2.appspot.com",
	"locationId": "us-central",
	"apiKey": "AIzaSyAsPzj-7OHYcaDbtMeientoGI_aJdvDt8k",
	"authDomain": "oportador-7aba2.firebaseapp.com",
	"messagingSenderId": "808751182390",
	"measurementId": "G-VM6QNCLLXD"
};